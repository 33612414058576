exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-newsletter-index-jsx": () => import("./../../../src/pages/newsletter/index.jsx" /* webpackChunkName: "component---src-pages-newsletter-index-jsx" */),
  "component---src-pages-tags-index-jsx": () => import("./../../../src/pages/tags/index.jsx" /* webpackChunkName: "component---src-pages-tags-index-jsx" */),
  "component---src-pages-talks-index-js": () => import("./../../../src/pages/talks/index.js" /* webpackChunkName: "component---src-pages-talks-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

